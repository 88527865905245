<template>
    <el-card style="min-height: 45rem">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form>
            <el-form-item label="顶部文章板块头图">
              <y-upload-img v-model="form.top_article_banner"></y-upload-img>
            </el-form-item>
            <el-form-item label="顶部文章板块数据">
              <el-select v-model="form.top_article_cate_id">
                <el-option :value="0" label="未设置"></el-option>
                <el-option v-for="(item,key) in albumCateOpts" :key="key" :value="item.id" :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品板块头图">
              <y-upload-img v-model="form.goods_banner"></y-upload-img>
            </el-form-item>
            <el-form-item label="商品板块背景图">
              <y-upload-img v-model="form.goods_bg"></y-upload-img>
            </el-form-item>
            <el-form-item label="画册板块头图">
              <y-upload-img v-model="form.album_top_banner"></y-upload-img>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form>
            <el-form-item label="全部文章板块头图">
              <y-upload-img v-model="form.all_article_top_banner"></y-upload-img>
            </el-form-item>
            <el-form-item label="全部文章板块侧图">
              <y-upload-img v-model="form.all_article_side_banner"></y-upload-img>
            </el-form-item>
            <el-form-item label="视频板块头图">
              <y-upload-img v-model="form.video_banner"></y-upload-img>
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
</template>

<script>

const EditFormTpl = {
  top_article_banner:"",
  top_article_cate_id:"",
  goods_banner:"",
  goods_bg:"",
  album_top_banner:"",
  all_article_top_banner:"",
  all_article_side_banner:"",
  video_banner:"",
}

import YUploadImg from "@/components/y-form/y-upload/y_upload_img";

export default {
  name: "indexConf",
  components: {
    YUploadImg,
  },
  data() {
    return {
      form: {
        ...EditFormTpl
      },
      albumCateOpts:[],
    }
  },
  mounted() {
    this.load();
    this.$u.api.album.article.cateAll().then(res=>{
      this.albumCateOpts = res;
    })
  },
  methods: {
    load() {
      this.$u.api.website.indexConf().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.website.indexConfEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>